import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import { renderBlocks } from '../components/blocks'
import { generateMetaDescriptionFromRichText } from '../utils/generateMetaDescriptionFromRichText'

const ProductTemplate = ({ data }) => {
  const {
    name,
    shortDescription,
    heroImage,
    childContentfulProductContentRichTextNode,
  } = data.contentfulProduct

  let ogImage
  try {
    ogImage = heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  return (
    <SeamlessLayout>
      <SEO
        title={name}
        description={
          shortDescription && !JSON.parse(shortDescription.internal.content)
            ? shortDescription.internal.content
            : generateMetaDescriptionFromRichText({
                richText: childContentfulProductContentRichTextNode,
                title: name,
              })
        }
        image={ogImage}
      />
      <SeamlessContainer>
        <PageTitle hidden>{name}</PageTitle>
        <RichContentContainer>
          {renderBlocks(
            childContentfulProductContentRichTextNode?.json || {
              content: [],
              data: {},
              nodeType: 'document',
            }
          )}
        </RichContentContainer>
      </SeamlessContainer>
    </SeamlessLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulProduct(slug: { eq: $slug }) {
      name
      slug
      shortDescription {
        internal {
          content
        }
      }
      childContentfulProductContentRichTextNode {
        json
      }
    }
  }
`

export default ProductTemplate

const SeamlessLayout = styled(Layout)`
  padding-top: 0;
  padding-bottom: 0;
`

const SeamlessContainer = styled(Container)`
  padding-top: 0;
  padding-bottom: 0;
`

const RichContentContainer = styled.div`
  margin-top: -144px;
  padding-top: 144px;

  & p:empty:last-child {
    margin-bottom: 0;
  }
`
